:root{
    --font--montserrat: "Montserrat", sans-serif;
    //FONT SIZES
--font-sm: 0.875rem; //14px
--font-med: 1rem; //16px
--font-mid: 1.25rem; //20px
--font-lg: 1.875rem; //30px
--font-xlg: 2.5rem; //41px
}

