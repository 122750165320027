 :root{
     --darkturqouise:hsl(167, 100%, 29%);
     --darkblue: hsl(233, 26%, 24%);
     --verylightturquoise:hsl(166, 41%, 86%);
     --white: hsl(0, 0%, 100%);
     --limegreen: hsl(126, 35%, 49%);
     --lightbrown: hsl(0, 0%, 25%);
     --lightturquoise: hsl(166, 41%, 89%);
     --turquoise: hsl(167, 100%, 39%);
     --green :hsl(130, 42%, 57%);
     --grey: hsl(0, 0%, 77%);
     --lightgrayishblue: hsl(220, 16%, 96%);
 }
 
  



