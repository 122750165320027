@use '../util/' as *;

.article{
    // background-color: var(--lightturquoise);
    background-color: var(--white);
    width: inherit;
       @include breakpoint(large){
        display: flex;
    }

  

}