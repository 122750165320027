@use '../util/' as *;
.feature{
    background-color:var(--lightgrayishblue);

        &__text{
            text-align: center;
        align-items: center;
        justify-content: space-around;
        
        &__body{
            padding-bottom: 1rem;
           @include breakpoint(large){
                //width: 60%;
                 padding:  1rem 4rem;
                   text-align:center;
                   align-items: center;
                   //width: auto;
                   
                }
                @include breakpoint-down(medium){
                    text-align: center;
                    padding: 1.75rem;

                }
            }
        }
        &__icon {
          width: 4.5rem; //72px
          height: 4.5rem; //72px
          background-color: var(--lightgrayishblue);
          border-radius: 50px;

          > img {
            
            width: 3.125rem; //50px
            height: 3.125rem;
            margin: 0.5625rem; //9px
          }
      
          @include breakpoint-down(medium) {
            margin: 0 auto;
          }
          @include breakpoint(large) {
            margin-bottom: 0.875rem;
          }
        }
    &__grid{
      display: grid;
      grid-template: 1fr;
      gap: 1.5rem;
        padding-top: 1rem;
      
        @include breakpoint-down(medium){
            grid-template-rows: repeat(1 ,1fr);
            grid-template-columns: repeat( );
            width: inherit;

            //height:15rem ;
            
        }
        @include breakpoint(large){
            width: 52.125rem;
            height: 20rem;
            padding: 1.5625rem 5.875rem;
            align-items: center;
            grid-template-columns: repeat( 3, 1fr);
        }
        &__item{
          background-color: var(--lightturquoise);
          &:hover{
            transform: scale(1.05);
                }
          p{
            font-size: var(--font-mid);
            padding: 2rem;
             text-align: center;
           }
           &_2{
             p{
               padding: 1.9rem;
             }
           }
          }
        }
    &__icon{
        position: relative;
        width: 50%;
        height: 30%;
        border-radius: 10px;
        background-color: var(--grey);
        margin: 1rem 2.5rem;
        padding: 1.5rem;
        @include breakpoint-down(medium){
            margin: 1rem auto;
        }
            > i{
                padding: 1rem auto;
                &::before{
                    color: var(--darkturqouise);
                       font-size: 5rem;
                       //margin: 2.5rem;
                       @include breakpoint(medium){
                           margin: 1.5rem;
                       }
                }
            }
        }
 
}
.vision {

    &__title {
      padding-bottom: .5rem;
      h2 {
        margin: 1.5625rem auto;
  
        @include breakpoint(medium) {
          text-align: left;
         
        }
      }
      p {
        text-align: left;
        @include breakpoint(medium) {
          text-align: left;
        }
      }
    }
  }

.card{
    display: grid;
    grid-template: 1fr;
    gap: 1.5rem;
    padding: 2rem 0;
    @include breakpoint-down(medium) {
      grid-template-columns: repeat(1, 1fr);
    }
    @include breakpoint(medium){
      grid-template-columns: repeat(2, 1fr);
     &_gtr{
      grid-template-rows: repeat(4, .5fr);
     
     }
    }
    
    &__image {
        height: 16rem;
        background-position:25% 19%;;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 7px; //7px
        @include breakpoint-down(medium){
          margin: 1rem;
          background-position: 25% 31%
          // background-position: top center;
          // height: 22rem;
        }
    }
    &__text {
        padding: 1.25rem;
        }
    &__title {
        font-size: var(--font-lg);
        padding-bottom: 0.75rem;
      }
      &__description {
        color: var(--lightbrown);
        font-size: var(--font-mid);
      }
}
