@use  '../util/' as *;
.header{
    margin: 0;
    &.open{
        .overlay{
            display:block;
        }
        .header__menu__toggle{
            z-index: 4;
            span:first-child{
                transform: rotate(45deg);
                width: 40px;
                margin: 1rem;
            }
            span:nth-child(2){
                display: none;
            }
            span:last-child{
                transform: rotate(-45deg);
                width: 40px;
            }
        }
    }
    .overlay{
        display: none;
        position:fixed;
        top: 0px;
        left: 0px;
        bottom: 0px;
        right: 0px;
        background:linear-gradient(to bottom, var(--verylightturquoise), transparent);
        z-index: 3;
    }
    &__logo{
        display: flex;
        justify-content: space-between;
        > img{
            @include breakpoint-down(medium){
                width:  16.875rem ;
                height: 4.75rem;
                margin: 1rem;
            };
            @include breakpoint(large){
            width: 14.375rem;
            height: 3.875rem;
            margin: 1.125rem  17rem ;
            };
            
        }
    }
    &__menu{
        background-color: var(--darkturqouise);
        padding-top: 1rem;
        height: 2.688rem;
        @include breakpoint-down(medium){
            padding: 0;
        }
        &__links{
            @include breakpoint-down(medium){
                padding: 1.2rem;
                margin-left: 3rem;
            }
            @include breakpoint(large){
                margin-left: 28rem;
                //margin-bottom: 1.5625rem;
            }
            
            > a{
                text-decoration: none;
                font-size: var(--font-sm);
                color: var(--white);
                &:hover{
                    color: var(--lightbrown);
                }
                &:not(:last-child){
                    margin-right:1.8125rem ;//29px
                }
            }

        }
        &__toggle{
            width: 4rem;
            height: 3.5rem;
            margin: 1rem;
            
            span{
                display:block;
                width: 30px;
                height: 3px;
                margin: .7rem 1rem;
                border-radius: 10%;
                background-color: var(--lightbrown);
                &:nth-child(2){
                    width: 35px
                }
                &:not(:last-child){
                    margin-bottom: 2px;
                }
                transform-origin: 7px 1px;
            }
        }
    }
    &__toggle{
        display: block;
        position: absolute;
        width: calc(100% - 3rem);
        left: 50%;
        top: 36%;
        transform: translateX(-50%);
        padding: 1rem;
        text-align: center;
        align-items: center;
        background-color: var(--white);
        z-index: 5;
        a {
            display: block;
            width: 100%;
            color: var(--turquoise);
            text-decoration: none;
            // transition: color 300ms ease-in-out;
            &:hover {
              color: var(--darkturqouise);
            }
            &:not(:last-child) {
              padding-bottom: 1rem;
            }
        }
    }
}