@use '../util/' as *;

h1,
h2,
h3 {
  font-family: var(--font--montserrat);
  margin-top: 0;
}
a,
a:visited, 
a:active{
    text-decoration: none;
}

p{
  margin: 0;
  padding: 0;
  text-decoration: none;
  color: var(--lightbrown);
  font-size: var(--font-sm);
}
label{
  display: block;
  margin: .5rem;
}
.p-1{
  margin: 0;
  padding: 1rem;
  text-decoration: none;
  color: var(--lightbrown);
  font-size: var(--font-sm);
}

//hide for tablet and mobile 
.hide-for-mobile{
  @include breakpoint-down(small){
    display: none;
  }
}
.hide-for-tablet{
  @include breakpoint-down(medium){
    display: none;
  }
}
//hide for desktop viewport widths
.hide-for-desktop{
  @include breakpoint(large){
    display: none;
  }
}