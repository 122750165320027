:root {
  --font--montserrat: "Montserrat", sans-serif;
  --font-sm: 0.875rem;
  --font-med: 1rem;
  --font-mid: 1.25rem;
  --font-lg: 1.875rem;
  --font-xlg: 2.5rem;
}

:root {
  --darkturqouise:hsl(167, 100%, 29%);
  --darkblue: hsl(233, 26%, 24%);
  --verylightturquoise:hsl(166, 41%, 86%);
  --white: hsl(0, 0%, 100%);
  --limegreen: hsl(126, 35%, 49%);
  --lightbrown: hsl(0, 0%, 25%);
  --lightturquoise: hsl(166, 41%, 89%);
  --turquoise: hsl(167, 100%, 39%);
  --green:hsl(130, 42%, 57%);
  --grey: hsl(0, 0%, 77%);
  --lightgrayishblue: hsl(220, 16%, 96%);
}

html {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font--montserrat);
  user-select: none;
}

.flex-ac {
  align-items: center;
}
.flex-jc {
  justify-content: space-between;
}
.flex-ta {
  text-align: center;
}

@media (min-width: 64em) {
  .container {
    padding: 1.5625rem 5.875rem;
  }
  .container-px {
    padding: 0 5.875rem;
  }
  .container-py {
    padding: 1.5625rem 0;
  }
  .container-pxy {
    padding: 2.875rem;
  }
  .container-mx {
    margin: 0 5.875rem;
  }
  .container-my {
    margin: 1.5625rem 0;
  }
  .container-mxy {
    margin: 2.875rem;
  }
}
.button {
  cursor: pointer;
  padding: 1rem;
  margin: 1rem;
  font-size: var(--font-mid);
  background: linear-gradient(to right, var(--green), var(--turquoise));
  color: var(--white);
  text-decoration: none;
}

.title {
  color: var(--darkturqouise);
  font-size: var(--font-lg);
  align-items: center;
  text-align: center;
}
@media (min-width: 64em) {
  .title {
    position: relative;
    padding: 1rem 2rem;
  }
}

.title-1 {
  color: var(--darkturqouise);
  font-size: var(--font-lg);
}

.blurb {
  margin-bottom: 2rem;
  color: var(--grey);
  font-size: var(--font-lg);
}
@media (max-width: 63.9375em) {
  .blurb {
    font-size: var(--font-mid);
    padding-bottom: 1rem;
  }
}

.corners {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  transition: all ease-in-out 300ms;
}
.corners-notrans {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
}

.noscroll {
  overflow: hidden;
}

.article__image {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: inherit;
  height: 40rem;
}
@media (max-width: 63.9375em) {
  .article__image {
    height: 20rem;
    background-position: 29%;
  }
}
@media (min-width: 64em) {
  .article__image {
    height: 20rem;
    width: 20rem;
    flex: 3;
  }
}

.article__text {
  flex: 2;
}
@media (max-width: 63.9375em) {
  .article__text {
    padding: 2rem;
  }
}
.article__text__title {
  padding: 0.5rem 2rem;
}

em {
  color: var(--darkturqouise);
}

h1,
h2,
h3 {
  font-family: var(--font--montserrat);
  margin-top: 0;
}

a,
a:visited,
a:active {
  text-decoration: none;
}

p {
  margin: 0;
  padding: 0;
  text-decoration: none;
  color: var(--lightbrown);
  font-size: var(--font-sm);
}

label {
  display: block;
  margin: 0.5rem;
}

.p-1 {
  margin: 0;
  padding: 1rem;
  text-decoration: none;
  color: var(--lightbrown);
  font-size: var(--font-sm);
}

@media (max-width: 39.9375em) {
  .hide-for-mobile {
    display: none;
  }
}

@media (max-width: 63.9375em) {
  .hide-for-tablet {
    display: none;
  }
}

@media (min-width: 64em) {
  .hide-for-desktop {
    display: none;
  }
}

.header {
  margin: 0;
}
.header.open .overlay {
  display: block;
}
.header.open .header__menu__toggle {
  z-index: 4;
}
.header.open .header__menu__toggle span:first-child {
  transform: rotate(45deg);
  width: 40px;
  margin: 1rem;
}
.header.open .header__menu__toggle span:nth-child(2) {
  display: none;
}
.header.open .header__menu__toggle span:last-child {
  transform: rotate(-45deg);
  width: 40px;
}
.header .overlay {
  display: none;
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background: linear-gradient(to bottom, var(--verylightturquoise), transparent);
  z-index: 3;
}
.header__logo {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 63.9375em) {
  .header__logo > img {
    width: 16.875rem;
    height: 4.75rem;
    margin: 1rem;
  }
}
@media (min-width: 64em) {
  .header__logo > img {
    width: 14.375rem;
    height: 3.875rem;
    margin: 1.125rem 17rem;
  }
}
.header__menu {
  background-color: var(--darkturqouise);
  padding-top: 1rem;
  height: 2.688rem;
}
@media (max-width: 63.9375em) {
  .header__menu {
    padding: 0;
  }
}
@media (max-width: 63.9375em) {
  .header__menu__links {
    padding: 1.2rem;
    margin-left: 3rem;
  }
}
@media (min-width: 64em) {
  .header__menu__links {
    margin-left: 28rem;
  }
}
.header__menu__links > a {
  text-decoration: none;
  font-size: var(--font-sm);
  color: var(--white);
}
.header__menu__links > a:hover {
  color: var(--lightbrown);
}
.header__menu__links > a:not(:last-child) {
  margin-right: 1.8125rem;
}
.header__menu__toggle {
  width: 4rem;
  height: 3.5rem;
  margin: 1rem;
}
.header__menu__toggle span {
  display: block;
  width: 30px;
  height: 3px;
  margin: 0.7rem 1rem;
  border-radius: 10%;
  background-color: var(--lightbrown);
  transform-origin: 7px 1px;
}
.header__menu__toggle span:nth-child(2) {
  width: 35px;
}
.header__menu__toggle span:not(:last-child) {
  margin-bottom: 2px;
}
.header__toggle {
  display: block;
  position: absolute;
  width: calc(100% - 3rem);
  left: 50%;
  top: 36%;
  transform: translateX(-50%);
  padding: 1rem;
  text-align: center;
  align-items: center;
  background-color: var(--white);
  z-index: 5;
}
.header__toggle a {
  display: block;
  width: 100%;
  color: var(--turquoise);
  text-decoration: none;
}
.header__toggle a:hover {
  color: var(--darkturqouise);
}
.header__toggle a:not(:last-child) {
  padding-bottom: 1rem;
}

.feature {
  background-color: var(--lightgrayishblue);
}
.feature__text {
  text-align: center;
  align-items: center;
  justify-content: space-around;
}
.feature__text__body {
  padding-bottom: 1rem;
}
@media (min-width: 64em) {
  .feature__text__body {
    padding: 1rem 4rem;
    text-align: center;
    align-items: center;
  }
}
@media (max-width: 63.9375em) {
  .feature__text__body {
    text-align: center;
    padding: 1.75rem;
  }
}
.feature__icon {
  width: 4.5rem;
  height: 4.5rem;
  background-color: var(--lightgrayishblue);
  border-radius: 50px;
}
.feature__icon > img {
  width: 3.125rem;
  height: 3.125rem;
  margin: 0.5625rem;
}
@media (max-width: 63.9375em) {
  .feature__icon {
    margin: 0 auto;
  }
}
@media (min-width: 64em) {
  .feature__icon {
    margin-bottom: 0.875rem;
  }
}
.feature__grid {
  display: grid;
  grid-template: 1fr;
  gap: 1.5rem;
  padding-top: 1rem;
}
@media (max-width: 63.9375em) {
  .feature__grid {
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat();
    width: inherit;
  }
}
@media (min-width: 64em) {
  .feature__grid {
    width: 52.125rem;
    height: 20rem;
    padding: 1.5625rem 5.875rem;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
  }
}
.feature__grid__item {
  background-color: var(--lightturquoise);
}
.feature__grid__item:hover {
  transform: scale(1.05);
}
.feature__grid__item p {
  font-size: var(--font-mid);
  padding: 2rem;
  text-align: center;
}
.feature__grid__item_2 p {
  padding: 1.9rem;
}
.feature__icon {
  position: relative;
  width: 50%;
  height: 30%;
  border-radius: 10px;
  background-color: var(--grey);
  margin: 1rem 2.5rem;
  padding: 1.5rem;
}
@media (max-width: 63.9375em) {
  .feature__icon {
    margin: 1rem auto;
  }
}
.feature__icon > i {
  padding: 1rem auto;
}
.feature__icon > i::before {
  color: var(--darkturqouise);
  font-size: 5rem;
}
@media (min-width: 40em) {
  .feature__icon > i::before {
    margin: 1.5rem;
  }
}

.vision__title {
  padding-bottom: 0.5rem;
}
.vision__title h2 {
  margin: 1.5625rem auto;
}
@media (min-width: 40em) {
  .vision__title h2 {
    text-align: left;
  }
}
.vision__title p {
  text-align: left;
}
@media (min-width: 40em) {
  .vision__title p {
    text-align: left;
  }
}

.card {
  display: grid;
  grid-template: 1fr;
  gap: 1.5rem;
  padding: 2rem 0;
}
@media (max-width: 63.9375em) {
  .card {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (min-width: 40em) {
  .card {
    grid-template-columns: repeat(2, 1fr);
  }
  .card_gtr {
    grid-template-rows: repeat(4, 0.5fr);
  }
}
.card__image {
  height: 16rem;
  background-position: 25% 19%;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 7px;
}
@media (max-width: 63.9375em) {
  .card__image {
    margin: 1rem;
    background-position: 25% 31%;
  }
}
.card__text {
  padding: 1.25rem;
}
.card__title {
  font-size: var(--font-lg);
  padding-bottom: 0.75rem;
}
.card__description {
  color: var(--lightbrown);
  font-size: var(--font-mid);
}

.has-fade {
  visibility: hidden;
}

@keyframes fade-in {
  from {
    visibility: hidden;
    opacity: 0;
  }
  1% {
    visibility: hidden;
    opacity: 1;
  }
  to {
    visibility: visible;
    opacity: 1;
  }
}
.fade-in {
  animation: fade-in ease-in-out 300ms forwards;
}

@keyframes fade-out {
  from {
    visibility: visible;
    opacity: 1;
  }
  99% {
    visibility: visible;
    opacity: 0;
  }
  to {
    visibility: hidden;
    opacity: 0;
  }
}
.fade-out {
  animation: fade-out 200ms forwards ease-in-out;
}

.hero {
  position: relative;
  background-image: url(/images/hero.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 55.4375rem;
  z-index: 1;
}
@media (max-width: 63.9375em) {
  .hero {
    background-position: 59%;
  }
}
.hero::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 1;
  background: linear-gradient(to bottom, transparent 85%, var(--white));
}
.hero__text {
  font-size: var(--font-lg);
  color: var(--white);
}
@media (max-width: 63.9375em) {
  .hero__text {
    position: absolute;
    top: 71%;
    left: 5%;
    align-items: center;
    text-align: left;
  }
}
@media (min-width: 40em) {
  .hero__text {
    width: 51%;
    align-items: center;
    text-align: left;
    position: absolute;
    top: 39%;
    left: 22%;
  }
}
.hero__text__1 {
  background-color: rgba(95, 191, 116, 0.698);
}
@media (max-width: 39.9375em) {
  .hero__text__1 {
    width: 66%;
    margin-bottom: 0.5rem;
    padding-left: 1rem;
  }
}
@media (min-width: 40em) {
  .hero__text__1 {
    width: 42%;
    margin-bottom: 0.5rem;
    padding-left: 2rem;
  }
}
.hero__text__2 {
  background-color: rgba(95, 191, 116, 0.698);
}
@media (max-width: 39.9375em) {
  .hero__text__2 {
    width: 84%;
    margin-bottom: 0.5rem;
    padding-left: 1rem;
  }
}
@media (min-width: 40em) {
  .hero__text__2 {
    width: 46%;
    margin-bottom: 0.5rem;
    padding-left: 2rem;
  }
}
.hero__text__3 {
  background-color: rgba(95, 191, 116, 0.698);
}
@media (max-width: 39.9375em) {
  .hero__text__3 {
    width: 103%;
    margin-bottom: 0.5rem;
    padding-left: 1rem;
  }
}
@media (min-width: 40em) {
  .hero__text__3 {
    width: 50%;
    padding-left: 2rem;
  }
}
.hero__button {
  height: 5.625rem;
  width: 5.5625rem;
  position: absolute;
  border-radius: 50%;
  top: 80%;
  left: 50%;
  border: 1px solid var(--limegreen);
}
.hero__button::before {
  content: "+";
  display: inline-block;
  font-size: 4.438rem;
  color: var(--limegreen);
  margin-left: 1.4rem;
  margin-bottom: 2rem;
  text-decoration: none;
}

.article {
  background-color: var(--white);
  width: inherit;
}
@media (min-width: 64em) {
  .article {
    display: flex;
  }
}

.footer {
  background-color: var(--darkblue);
  color: var(--white);
  padding: 2.5rem;
  text-align: center;
}
@media (min-width: 64em) {
  .footer {
    display: grid;
    grid-template-columns: repeat(3, 1fr) 2fr;
    grid-template-rows: repeat(1, 1fr) 0.5fr;
    grid-template-areas: "logo links1 links2 cta" "social links1 links2 copyright";
    justify-items: start;
    padding-bottom: 0;
  }
}
.footer a {
  color: var(--white);
}
.footer__logo {
  display: inline-block;
}
@media (min-width: 64em) {
  .footer__logo {
    grid-area: logo;
  }
}
@media (max-width: 63.9375em) {
  .footer__logo {
    padding-bottom: 1rem;
  }
}
@media (max-width: 63.9375em) {
  .footer__social {
    margin-bottom: 1.8rem;
  }
}
@media (min-width: 64em) {
  .footer__social {
    grid-area: social;
    align-self: flex-start;
  }
}
.footer__social a {
  display: inline-block;
}
.footer__social a:not(:last-child) {
  margin-right: 1rem;
}
.footer__links {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 2.25;
}
@media (min-width: 64em) {
  .footer__links {
    align-items: flex-start;
  }
}
@media (min-width: 64em) {
  .footer__links__col1 {
    grid-area: links1;
  }
}
@media (min-width: 64em) {
  .footer__links__col2 {
    grid-area: links2;
  }
}
@media (max-width: 63.9375em) {
  .footer__links__col2 {
    margin-bottom: 1.8rem;
  }
}
@media (min-width: 64em) {
  .footer__cta {
    grid-area: cta;
    text-align: end;
    justify-self: end;
  }
}
@media (max-width: 87.4375em) {
  .footer__cta {
    margin: 1.875rem;
  }
}
.footer__cta a.button {
  margin-bottom: 1.875rem;
}
@media (max-width: 63.9375em) {
  .footer__copyright {
    margin: 1.8rem;
  }
}
@media (min-width: 64em) {
  .footer__copyright {
    grid-area: copyright;
    margin-top: 1.8rem;
    align-self: flex-end;
  }
}

.contact {
  background-color: var(--limegreen);
  color: var(--white);
  margin: 0 auto;
}
.contact-container {
  text-align: center;
}
.contact-container form {
  text-align: center;
  align-items: center;
  width: auto;
  background-color: var(--turquoise);
}
.contact-container form ul {
  display: flex;
  list-style-type: none;
  flex-flow: row wrap;
  gap: 2rem;
  justify-content: center;
  text-align: left;
}
.contact-container form ul input {
  font-family: var(--font-montserrat);
  padding: 0.5rem;
}

.status {
  width: 1100px;
  margin: auto;
  background: linear-gradient(var(--limegreen), var(--turquoise));
}