@use  '../util/' as *;

.hero{
position: relative;
 background-image: url(/images/hero.jpg);
 background-size: cover;
 background-repeat: no-repeat;
 background-position: center center;
 min-height: 55.4375rem;
 @include breakpoint-down(medium){
 background-position: 59%;
    
 }
 z-index: 1;
    &::before{
        content: ""; 
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
        opacity: 1;

        background: linear-gradient( to bottom , transparent 85%, var(--white));

    }


 &__text{
     font-size: var(--font-lg);
    color: var(--white);
     @include breakpoint-down(medium){
        position: absolute;
        top: 71%;
        left: 5%;
        align-items: center;
        text-align: left;
     }
     @include breakpoint(medium){
        width: 51%;
        align-items: center;
        text-align: left;
        position: absolute;
        top: 39%;
        left: 22%;
        
    } 
    &__1{
        
        background-color:rgba(95, 191, 116, 0.698);
        @include breakpoint-down(small) {
            width: 66%;
            margin-bottom: .5rem;
            padding-left: 1rem;
        }
        @include breakpoint(medium){
            width: 42%;
            margin-bottom: .5rem;
            padding-left: 2rem;
        }
    }
    
    &__2{
        background-color:rgba(95, 191, 116, 0.698);
        @include breakpoint-down(small) {
            width: 84%;
            margin-bottom: .5rem;
            padding-left: 1rem;
            
        }
         @include breakpoint(medium){
            width: 46%;
            margin-bottom: .5rem;
            padding-left: 2rem;
        }
    }
     &__3{
        background-color:rgba(95, 191, 116, 0.698);
        @include breakpoint-down(small) {
            width: 103%;
            margin-bottom: .5rem;
            padding-left: 1rem;
        }
          @include breakpoint(medium){
            width: 50%;
            padding-left: 2rem;
        }

    }
}
    &__button{
        height: 5.625rem;
        width: 5.5625rem;
        position: absolute;
        border-radius: 50%;
        top: 80%;
        left: 50%;
        border: 1px solid var(--limegreen);
        &::before{
            content: "+";
            display: inline-block;
            font-size: 4.438rem;
            color: var(--limegreen);
            margin-left: 1.4rem;
            margin-bottom: 2rem;
            text-decoration: none;
        }
    }

}
 