@use '../util/' as *;


html{
box-sizing: border-box;
}
body{
    margin: 0;
    padding: 0;
    font-family: var(--font--montserrat);
    user-select: none;
}
.flex{
    &-ac{
        align-items: center;
    }
    &-jc{
        justify-content: space-between;
    }
    &-ta{
        text-align: center;
    }
}
@include breakpoint(large){
    .container{
        padding: 1.5625rem 5.875rem;
        &-px{
            padding:  0 5.875rem;
        }
        &-py{
            padding: 1.5625rem 0;
        }
        &-pxy{
            padding: 2.875rem;
        }
        
        &-mx{
            margin:  0 5.875rem;
        }
        &-my{
            margin: 1.5625rem 0;
        }
        &-mxy{
            margin: 2.875rem ;
        }
    
        @include breakpoint-down(large){
        }
    }
    
}
.button{
    cursor: pointer;
    padding: 1rem;
    margin: 1rem;
    font-size: var(--font-mid);
    background: linear-gradient( to right, var(--green) ,var(--turquoise));
    color: var(--white);
    text-decoration: none;
    
}

.title{
    color: var(--darkturqouise);
    font-size: var(--font-lg);
    align-items: center;
    text-align: center;
    //padding-top: 1rem;
    @include breakpoint(large){
        position: relative;
        padding: 1rem 2rem;
        // left: 38%;
        // width: 30%; 
        
    } 
}
.title-1{
    color: var(--darkturqouise);
    font-size: var(--font-lg);
    
}
.blurb{
margin-bottom: 2rem;
color: var(--grey);
font-size: var(--font-lg);
@include breakpoint-down(medium){
    font-size: var(--font-mid);
    padding-bottom: 1rem;
    
}

}
.corners{
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    transition:  all ease-in-out 300ms ;

    &-notrans{
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    }
}
.noscroll{
    overflow: hidden;
}
.article__image{
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width:inherit;
    height: 40rem;
    @include breakpoint-down(medium){
        height: 20rem;
        background-position: 29%;
        //margin: 0.2rem;
    }
    
    @include breakpoint(large){
        height: 20rem;
        width: 20rem;
        flex: 3;
    }
}
.article__text{
    flex: 2; 
    @include breakpoint-down(medium){
        padding: 2rem;
    }
    &__title{
        padding: 0.5rem  2rem ;
    }
}




em{
    color: var(--darkturqouise);
}